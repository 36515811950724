<template>
  <div>
    <customer-limit ref="customerLimitmodule" v-if="editCustomer.show==true" @change="handleGoBack" @submit="handleGetCustomerLimitItems" :isNoView="editCustomer.isNoView" :successList="editCustomer.target.customerLimitItems.length>0?editCustomer.target.customerLimitItems:[]" />
    <template v-else>
      <div class="flex h c" style="margin-bottom: 12px;">
        <el-button type="primary" icon="el-icon-arrow-left" @click="handleBack">返回</el-button>
        <div class="padding-0-20" v-if="form">
          <div class="fc-g" style="font-size: 12px;">当前活动</div>
          <div style="margin-top: 2px;">{{form.name || "-"}}</div>
        </div>
      </div>

      <el-tabs v-model="currentTab" v-if="form">
        <el-tab-pane label="基本信息" name="base">
          <el-form ref="form" :model="form" :rules="rules" label-position="top" hide-required-asterisk :class="{layout_disabled: !editable}">
            <el-form-item label="流水号" style="width: 400px;" v-if="form.code">
              <el-input readonly :value="form.code" />
            </el-form-item>
            <div class="h sb wrap" style="width: 840px;">
              <el-form-item label="活动名称" prop="name" style="width: 400px;">
                <el-input v-model="form.name" :maxlength="70" placeholder="请输入活动名称" />
              </el-form-item>
              <el-form-item label="活动开始时间" prop="beginTime" style="width: 200px;">
                <el-date-picker v-model="form.beginTime" type="datetime" format="yyyy/MM/dd HH:mm:ss" value-format="timestamp" default-time="00:00:00" style="width: 100%;" />
              </el-form-item>
              <el-form-item label="活动结束时间" prop="endTime" style="width: 200px;">
                <el-date-picker v-model="form.endTime" type="datetime" format="yyyy/MM/dd HH:mm:ss" value-format="timestamp" default-time="23:59:59" style="width: 100%;" />
              </el-form-item>

              <el-form-item label="每客户享受本促销最多次数" prop="maxTimes" style="width: 400px;">
                <div class="h c">
                  <el-input-number v-model.number="form.maxTimes" :min="0" :max="999999" controls-position="right" style="width: 150px;" />
                  <div class="padding-0-10 fc-g">0表示不限制享受次数</div>
                </div>
              </el-form-item>

              <el-form-item prop="mutex" style="width: 200px;">
                <div slot="label" class="h c">
                  <b>与整单优惠活动互斥&nbsp;</b>
                  <el-tooltip placement="top">
                    <div slot="content" style="width: 240px; line-height: 1.5;">组合销售中的商品是否还可以参与整单优惠活动；启用互斥后则不可再参加。</div>
                    <i class="el-icon-question"></i>
                  </el-tooltip>
                </div>
                <el-checkbox v-model="form.mutex">启用</el-checkbox>
              </el-form-item>

              <div class="h" style="width: 840px;">
                <el-form-item label="促销维度" prop="targetType" style="width: 150px; margin-right: 10px;">
                  <quick-select v-model="form.targetType" :options="targetTypes" display-field="label" :before-change="handleTargetTypeBeforeChange" @change="handleTargetTypeChange" style="width: 100%;" />
                </el-form-item>
                <el-form-item label="　" key="_target_type_1" class="flex" v-if="form.targetType=='distributor'">
                  <datagrid-picker v-model="form.target" multiple submit url="api/distributor" value-field="enterpriseId" query-value-field="ids" :query-define="distributorQueryDefine" :show-index="false" placeholder="请选择经销商" style="width: 100%;">
                    <div class="padding-10 bc-l" slot="banner" slot-scope="scope">
                      <el-input v-if="$erp.enable()" v-model="distributorQueryDefine.erpId" :validate-event="false" clearable placeholder="ERP编号搜索" @clear="scope.query" @keyup.enter.native="toQuery" style="width: 130px;" />
                      <el-input :maxlength="20" v-model="distributorQueryDefine.name" @keypress.enter.native="scope.query" @clear="scope.query" :validate-event="false" clearable placeholder="输入经销商名称进行搜索" style="width: 240px;" />
                      <el-button type="success" icon="el-icon-search" @click.stop="scope.query" />
                    </div>
                    <el-table-column prop="erpId" label="ERP编码" min-width="130" v-if="$erp.enable()" />
                    <el-table-column prop="name" label="经销商" min-width="160" />
                    <el-table-column label="所在地区" min-width="200" :formatter="v=>{return v.provinceName+'/'+v.cityName + '/' + v.districtName}" />
                  </datagrid-picker>
                </el-form-item>
                <el-form-item label="　" key="_target_type_2" class="flex" v-else-if="form.targetType=='distributorType'">
                  <datagrid-picker v-model="form.target" multiple submit url="api/distributorLeve" query-value-field="ids" :show-index="false" placeholder="请选择经销商类型" style="width: 100%;">
                    <el-table-column prop="name" label="经销商类型" min-width="160" />
                  </datagrid-picker>
                </el-form-item>
                <el-form-item label="　" key="_target_type_3" class="flex" v-else-if="form.targetType=='distributorGroup'">
                  <datagrid-picker v-model="form.target" multiple submit url="api/distributorGroup" query-value-field="ids" :query-define="distributorGroupQueryDefine" :show-index="false" placeholder="请选择经销商组" style="width: 100%;">
                    <div class="padding-10 bc-l" slot="banner" slot-scope="scope">
                      <el-input :maxlength="20" v-model="distributorGroupQueryDefine.name" @keypress.enter.native="scope.query" @clear="scope.query" :validate-event="false" clearable placeholder="输入经销商组名称进行搜索" style="width: 240px;" />
                      <el-button type="success" icon="el-icon-search" @click.stop="scope.query" />
                    </div>
                    <el-table-column prop="name" label="经销商组名称" min-width="160" />
                  </datagrid-picker>
                </el-form-item>
                <el-form-item label="　" key="_target_type_4" class="flex" v-else-if="form.targetType=='distributorArea'">
                  <quick-cascader v-model="form.target" url="api/area" multiple flat placeholder="请选择区域" filterable style="width: 100%;" />
                </el-form-item>
              </div>
            </div>
          </el-form>
        </el-tab-pane>

        <el-tab-pane label="组合列表" name="detail">
          <div class="h c" v-if="editable">
            <el-button type="primary" @click="handleAddItem">新增组合套餐</el-button>
          </div>

          <template v-if="form.items && form.items.length">
            <div style="margin-top: 20px;" v-if="editable">
              <template v-for="(item,index) in form.items">
                <el-form class="marketing-combine-edit-form" :key="item._id" :ref="`item_${item._id}`" :model="item" :rules="itemRules" label-position="right" label-width="75px" label-suffix=":" hide-required-asterisk>
                  <div class="padding-10-0 h c">
                    <b class="fc-g">组合套餐{{index + 1}}</b>
                    <div class="flex"></div>
                    <el-button type="text" size="mini" @click="handleRemoveItem(item)">删除组合</el-button>
                  </div>
                  <div class="h">
                    <div class="v" style="width: 480px;">
                      <el-form-item prop="name" label="套餐名称">
                        <el-input v-model="item.name" :maxlength="70" />
                      </el-form-item>
                      <el-form-item label="促销方式" prop="method">
                        <radio-group v-model="item.method" button :options="methods" />
                      </el-form-item>
                    </div>
                    <el-form-item label="套餐图片" label-width="100px">
                      <file-uploader v-model="item.coverImg" :entity-id="item.fileId" folder="marketing/combine/cover" :thumbnail-size="87" />
                    </el-form-item>
                    <el-form-item label="更多图片" label-width="100px" class="flex">
                      <file-uploader :entity-id="item.fileId" folder="marketing/combine/detail" multiple :thumbnail-size="87" />
                    </el-form-item>
                  </div>
                  <div class="h c">
                    <div class="h c" style="width: 700px;">
                      <el-form-item label="套餐总量" prop="allQuantity">
                        <div class="h c">
                          <el-input-number v-model="item.allQuantity" :precision="0" :min="0" :max="9999" :step="1" controls-position="right" @change="$checkNaN(item, 'allQuantity', 0, checkAllQuantitySet)" style="width: 80px;" />
                          <div class="fc-g">&nbsp;0表示不限量</div>
                        </div>
                      </el-form-item>
                      <el-form-item label="起订量" prop="minBuyQuantity" label-width="70px">
                        <el-input-number v-model="item.minBuyQuantity" :precision="0" :min="1" :max="item.allQuantity || 9999" :step="1" controls-position="right" @change="$checkNaN(item, 'minBuyQuantity', 1)" style="width: 80px;" />
                      </el-form-item>
                      <el-form-item label="每客户限量" prop="customerMaxQuantity" label-width="90px">
                        <div class="h c">
                          <el-input-number v-model="item.customerMaxQuantity" :precision="0" :min="0" :max="9999" :step="1" controls-position="right" @change="$checkNaN(item, 'customerMaxQuantity', 0, checkCustomerQuantitySet)" style="width: 80px;" />
                          <div class="fc-g">&nbsp;0表示不限量</div>
                        </div>
                      </el-form-item>
                    </div>

                    <el-form-item label-width="20px">
                      <div class="c h">
                        <div @click="handleCustomerLimit(item,true)" style="color:#409EFF;cursor:pointer">自定义客户限量</div>
                        <div>
                          <span v-if="item.customerLimitItems.length>0" style="color:#67C23A">（已设置）</span>
                          <span v-else style="color:#F56C6C">（未设置）</span>
                        </div>
                      </div>
                    </el-form-item>
                    <el-form-item label="是否启用" prop="enabled" label-width="100px">
                      <el-switch v-model="item.enabled"></el-switch>
                    </el-form-item>
                  </div>
                  <el-form-item prop="itemGoods" label="套餐设置">
                    <div class="h c" style="margin-bottom: 10px;">
                      <el-button type="primary" @click="handleSubItemAdd(item)" size="mini">添加设置</el-button>
                      <el-button type="danger" @click="handleSubItemClear(item)" size="mini">清空设置</el-button>
                    </div>
                    <el-table :key="item._id" :data="item.items" border>
                      <el-table-column key="detail" label="详细内容" min-width="640" class-name="el-table--inner-column">
                        <template slot-scope="scope">
                          <div class="padding-10 h c" style="border-bottom: #f2f2f2 solid 1px; background-color: #f8f8f9;">
                            <b>内容{{scope.$index + 1}}</b>
                            <div class="flex"></div>
                            <el-button size="mini" type="text" @click="handleGoodsSelect(scope.row)">添加商品</el-button>
                            <el-button size="mini" type="text" @click="handleGoodsClear(scope.row)">清除商品</el-button>
                          </div>
                          <el-table :data="scope.row.goodsList" row-key="_id" class="el-table--inner">
                            <el-table-column label="商品名称" min-width="360">
                              <template slot-scope="scope1">
                                <span>{{scope1.row.goodsName}}</span>
                                <span class="fc-g">（{{$goodsSpecConvert(scope1.row.spec)}}）</span>
                              </template>
                            </el-table-column>
                            <el-table-column key="save" label="单价减价" width="180" v-if="item.method === 'save'">
                              <template slot-scope="scope1">
                                <price-input v-model="scope1.row.saveAmount" style="width: 100%;" />
                              </template>
                            </el-table-column>
                            <el-table-column key="price" label="单价一口价" width="180" v-else-if="item.method === 'price'">
                              <template slot-scope="scope1">
                                <price-input v-model="scope1.row.price" style="width: 100%;" />
                              </template>
                            </el-table-column>
                            <el-table-column width="50">
                              <div class="row-commands" slot-scope="scope1">
                                <el-button size="mini" type="text" @click="handleRemoveGoods(scope.row.goodsList, scope1.row)">删除</el-button>
                              </div>
                            </el-table-column>
                          </el-table>
                          <div class="h c" v-if="item.method === 'save' || item.method === 'price'">
                            <div class="flex ta-r lh-250 padding-0-10">统一设置：</div>
                            <div class="no-flex padding-10" style="width: 180px">
                              <price-input v-model="scope.row._unify.saveAmount" style="width: 100%" @change="handleSubItemUnifySet(scope.row, 'saveAmount')" v-if="item.method === 'save'" />
                              <price-input v-model="scope.row._unify.price" style="width: 100%" @change="handleSubItemUnifySet(scope.row, 'price')" v-else-if="item.method === 'price'" />
                            </div>
                            <div class="no-flex" style="width: 50px"></div>
                          </div>
                        </template>
                      </el-table-column>

                      <el-table-column key="quantity" label="数量" width="150">
                        <template slot-scope="scope">
                          <el-input-number v-model="scope.row.quantity" :min="1" :step="1" :precision="0" size="small" controls-position="right" style="width: 100%;" />
                        </template>
                      </el-table-column>
                      <el-table-column key="discount" label="打折" width="150" v-if="item.method === 'discount'">
                        <template slot-scope="scope">
                          <percent-input v-model="scope.row.discount" :precision="1" :max="10" :base="1" symbol="折" style="width: 100%;" />
                        </template>
                      </el-table-column>
                      <el-table-column width="50">
                        <div class="row-commands" slot-scope="scope">
                          <el-button size="mini" type="text" @click="handleSubItemRemove(item, scope.row)">删除</el-button>
                        </div>
                      </el-table-column>
                    </el-table>
                  </el-form-item>
                </el-form>
              </template>
            </div>
            <div v-else>
              <template v-for="(item,index) in form.items">
                <div class="marketing-combine-edit-item" :key="index">
                  <el-form :model="item" label-width="90px" label-position="right" label-suffix="：">
                    <form-info-item label="套餐名称">
                      <b class="fs-large">{{item.name}}</b>
                    </form-info-item>
                    <div class="h">
                      <div>
                        <form-info-item label="促销方式">{{methodObjs[item.method]}}</form-info-item>
                        <form-info-item label="套餐总量">{{item.allQuantity || "不限量"}}</form-info-item>
                        <form-info-item label="起订量">{{item.minBuyQuantity}}</form-info-item>
                        <form-info-item label="每客户限量">{{item.customerMaxQuantity || "不限量"}}</form-info-item>

                        <form-info-item>
                          <div class="c h">
                            <div @click="handleCustomerLimit(item,false)" style="color:#409EFF;cursor:pointer">自定义客户限量</div>
                            <div>
                              <span v-if="item.customerLimitItems.length>0" style="color:#67C23A">（已设置）</span>
                              <span v-else style="color:#F56C6C">（未设置）</span>
                            </div>
                          </div>
                        </form-info-item>
                      </div>
                      <form-info-item label="套餐图片" label-width="100px" content-style="padding: 0;">
                        <file-uploader :entity-id="item.fileId" folder="marketing/combine/cover" readonly :thumbnail-size="86" />
                      </form-info-item>
                      <form-info-item label="更多图片" label-width="100px" content-style="padding: 0;">
                        <file-uploader :entity-id="item.fileId" folder="marketing/combine/detail" multiple readonly :thumbnail-size="86" />
                      </form-info-item>
                    </div>
                    <form-info-item label="套餐设置">
                      <el-table :data="item.items" border>
                        <el-table-column key="detail" label="详细内容" min-width="640" class-name="el-table--inner-column">
                          <template slot-scope="scope">
                            <div class="padding-10" style="border-bottom: #f2f2f2 solid 1px; background-color: #f8f8f9;">
                              <b>内容{{scope.$index + 1}}</b>
                            </div>
                            <el-table :data="scope.row.goodsList" row-key="_id" class="el-table--inner">
                              <el-table-column label="商品名称" min-width="360">
                                <template slot-scope="scope1">
                                  <span>{{scope1.row.goodsName}}</span>
                                  <span class="fc-g">（{{$goodsSpecConvert(scope1.row.spec)}}）</span>
                                </template>
                              </el-table-column>
                              <el-table-column key="save" prop="saveAmount" label="单价减价" width="150" :formatter="$price" v-if="item.method === 'save'" />
                              <el-table-column key="price" prop="price" label="单价一口价" width="150" :formatter="$price" v-else-if="item.method === 'price'" />
                            </el-table>
                          </template>
                        </el-table-column>

                        <el-table-column label="数量" prop="quantity" width="180" />
                        <el-table-column key="discount" label="打折" width="180" v-if="item.method === 'discount'">
                          <template slot-scope="scope">{{Math.round(scope.row.discount * 1000) / 100}}折</template>
                        </el-table-column>
                      </el-table>
                    </form-info-item>
                    <div class="h c">
                      <form-info-item label="状态">{{item.enabled ? "启用中" : "未启用"}}&emsp;</form-info-item>

                      <el-button type="danger" size="mini" @click="handleSetEnable(item)" v-if="item.enabled && checkPermission(['MARKET_MANAGE','MARKET_MANAGE_START'])">停用</el-button>
                      <el-button type="success" size="mini" @click="handleSetEnable(item)" v-else-if="checkPermission(['MARKET_MANAGE','MARKET_MANAGE_START'])">启用</el-button>
                    </div>
                  </el-form>
                </div>
              </template>
            </div>
          </template>
          <div class="padding-20-0 fc-g" v-else>目前还没有创建组合套餐</div>
        </el-tab-pane>
      </el-tabs>

      <div class="h c">
        <el-button type="primary" @click="doSave" :disabled="operaing" :loading="saving" v-if="editable">保存组合销售</el-button>
        <template v-if="form && form.id">
          <el-button type="danger" @click="doStop" :disabled="saving" :loading="operaing" v-if="form.status === 'start' && checkPermission(['MARKET_MANAGE','MARKET_MANAGE_START'])">反审组合销售</el-button>
          <el-button type="success" @click="doStart" :disabled="saving" :loading="operaing" v-else-if="checkPermission(['MARKET_MANAGE','MARKET_MANAGE_START'])">审核组合销售</el-button>
        </template>
      </div>

      <sku-selector ref="goodsSel" title="选择商品" request-url="api/goods/sku" :request-params="{onShelf: true, sort: 'code,asc'}" multiple :exclude-keys="excludeSkus" :show-button="false" :show-details="false" @submit="handleAddGoods" />
    </template>
  </div>
</template>

<script>
import { combine } from "@/api/marketing";
import skuSelector from "@/views/assembly/skuSelect";
import request from "@/utils/request";
import checkPermission from "@/utils/permission";
import customerLimit from "./customerLimit";
export default {
  components: { skuSelector, customerLimit },
  props: {
    id: String | Number,
    isCopy: Boolean,
    readonly: Boolean,
  },
  data() {
    return {
      customerLimitItemsData: [],
      editCustomer: {
        isNoView: true,
        target: null,
        show: false,
      },
      form: null,
      saving: false,
      operaing: false,
      maxItemIndex: 0,
      methods: [
        { id: "discount", label: "打折" },
        { id: "save", label: "减价" },
        { id: "price", label: "一口价" },
      ],
      methodObjs: {
        discount: "打折",
        save: "减价",
        price: "一口价",
      },
      targetTypes: [
        { id: "all", label: "全部" },
        { id: "distributor", label: "经销商" },
        { id: "distributorType", label: "经销商类型" },
        { id: "distributorGroup", label: "经销商组" },
        { id: "distributorArea", label: "区域" },
      ],
      distributorQueryDefine: {
        name: null,
        erpId: null,
      },
      distributorGroupQueryDefine: {
        name: "",
      },
      currentTab: "base",
      currentItem: null,
      loading: false,
      defaultTime: ["00:00:00", "23:59:59"],
      dateRangeOptions: {
        shortcuts: [
          {
            text: "最近一周",
            onClick(picker) {
              const now = new Date();
              const start = new Date(
                now.getFullYear(),
                now.getMonth(),
                now.getDate()
              );
              const end = new Date(
                now.getFullYear(),
                now.getMonth(),
                now.getDate() + 6,
                23,
                59,
                59
              );
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const now = new Date();
              const start = new Date(
                now.getFullYear(),
                now.getMonth(),
                now.getDate()
              );
              const end = new Date(
                now.getFullYear(),
                now.getMonth() + 1,
                now.getDate(),
                23,
                59,
                59
              );
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const now = new Date();
              const start = new Date(
                now.getFullYear(),
                now.getMonth(),
                now.getDate()
              );
              const end = new Date(
                now.getFullYear(),
                now.getMonth() + 3,
                now.getDate(),
                23,
                59,
                59
              );
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
      rules: {
        name: [
          { required: "true", message: "活动名称为必填项", trigger: "blur" },
        ],
        beginTime: [
          {
            required: "true",
            message: "活动开始时间为必填项",
            trigger: "blur",
          },
        ],
        endTime: [
          {
            required: "true",
            message: "活动结束时间为必填项",
            trigger: "blur",
          },
        ],
      },
      itemRules: {
        name: [
          {
            required: "true",
            message: "组合套餐名称为必填项",
            trigger: "blur",
          },
        ],
        items: [
          { required: true, message: "请选择套餐商品" },
          { type: "array", min: 1, message: "请选择套餐商品" },
        ],
      },
    };
  },
  computed: {
    editable() {
      // console.log(this.readonly);
      return this.form && this.form.status === "init" && !this.readonly;
    },
    excludeSpus() {
      if (
        this.currentItem &&
        this.currentItem.itemGoods &&
        this.currentItem.itemGoods.length
      ) {
        return this.currentItem.itemGoods
          .filter((o) => {
            return o.goodsType === "spu";
          })
          .map((o) => {
            return o.goodsId;
          });
      }
      return null;
    },
    excludeSkus() {
      if (
        this.currentItem &&
        this.currentItem.goodsList &&
        this.currentItem.goodsList.length
      ) {
        return this.currentItem.goodsList.map((o) => {
          return o.goodsId;
        });
      }
      return null;
    },
  },
  methods: {
    checkPermission,
    handleBack() {
      this.$emit("cancel");
    },
    handleGoBack() {
      this.editCustomer.show = false;
    },
    handleGetCustomerLimitItems(list) {
      if (this.editCustomer.target) {
        this.editCustomer.target.customerLimitItems = list;
        this.editCustomer.show = false;
      }
    },
    handleSubItemAdd(item) {
      let so = {
        _id: this.$uuid(),
        _unify: {
          saveAmount: 0,
          price: 0,
        },
        discount: 1,
        displayNo: ++item._maxItemIndex,
        goodsList: [],
        oldPrice: null,
        quantity: 1,
      };
      item.items.push(so);
      this.handleGoodsSelect(so);
    },
    handleSubItemRemove(item, d) {
      if (item && item.items && item.items.length) {
        let inx = item.items.indexOf(d);
        if (inx >= 0) item.items.splice(inx, 1);
      }
    },
    handleSubItemClear(item) {
      if (item.items && item.items.length) {
        this.$confirm("确定清空该组合下的所有设置吗?", "操作确认", {
          type: "warning",
        }).then(() => {
          item.items = [];
          this.$message({
            type: "success",
            message: "清空成功!",
          });
        });
      }
    },
    handleGoodsSelect(item) {
      this.currentItem = item;
      this.$refs.goodsSel && this.$refs.goodsSel.open();
    },
    handleAddGoods(res) {
      if (this.currentItem && res && res.length) {
        res.forEach((d) => {
          this.currentItem.goodsList.push({
            _id: this.$uuid(),
            displayNo: ++this.currentItem._maxItemIndex,
            saveAmount: 0,
            goodsId: d.id,
            spec: d.specs,
            spuId: d.spuId,
            price: d.price,
            goodsName: d.name,
            code: d.code,
            erpCode: d.erpCode,
            quantity: 0,
            oldPrice: d.price ? d.price : 0,
          });
        });
      }
    },
    handleRemoveGoods(target, d) {
      if (target && target.length) {
        let inx = target.indexOf(d);
        if (inx >= 0) target.splice(inx, 1);
      }
    },
    handleGoodsClear(item) {
      if (item.goodsList && item.goodsList.length) {
        this.$confirm("确定清空该内容下的所有商品?", "操作确认", {
          type: "warning",
        }).then(() => {
          item.goodsList = [];
          this.$message({
            type: "success",
            message: "清空成功!",
          });
        });
      }
    },
    handleAddItem() {
      this.maxItemIndex++;
      this.form.items.push({
        _id: this.$uuid(),
        _maxItemIndex: 0,
        name: "",
        method: "discount",
        minBuyQuantity: 1,
        allQuantity: 0,
        customerMaxQuantity: 0,
        customerLimitItems: [],
        fileId: this.$uuid(),
        coverImg: null,
        displayNo: this.maxItemIndex,
        enabled: true,
        items: [],
      });
    },
    // 删除组合
    handleRemoveItem(item) {
      this.$confirm("确定删除该组合吗?", "操作确认", {
        type: "warning",
      }).then(() => {
        let inx = this.form.items.indexOf(item);
        this.form.items.splice(inx, 1);
        this.$message({
          type: "success",
          message: "删除成功!",
        });
      });
    },
    handleTargetTypeBeforeChange() {
      return this.$confirm(
        "切换促销纬度将会重置所选的维度中的数据,确定切换吗？",
        "提示",
        {
          type: "warning",
        }
      );
    },
    handleTargetTypeChange() {
      this.form.target = [];
    },

    handleSetEnable(item) {
      if (item && item.id) {
        this.$confirm(
          item.enabled
            ? "确定要停用该组合套餐吗？"
            : "确定要启用该组合套餐吗？",
          "操作确认",
          { type: "warning" }
        ).then((_) => {
          request({
            url:
              "api/groupSales/item/" +
              (item.enabled ? "stop/" : "start/") +
              item.id,
            method: "put",
          }).then((_) => {
            this.$notify({
              title: item.enabled ? "停用组合套餐成功" : "启用组合套餐成功",
              type: "success",
              duration: 2500,
            });
            item.enabled = !item.enabled;
          });
        });
      }
    },

    handleSubItemUnifySet(subItem, type) {
      if (type && subItem && subItem.goodsList && subItem.goodsList.length) {
        subItem.goodsList.forEach((o) => {
          o[type] = subItem._unify[type];
        });
      }
    },

    checkAllQuantitySet(item) {
      if (!isNaN(item.allQuantity) && item.allQuantity > 0) {
        if (item.allQuantity < item.minBuyQuantity) {
          item.allQuantity = item.minBuyQuantity;
        }
      }
    },

    checkCustomerQuantitySet(item) {
      if (!isNaN(item.customerMaxQuantity) && item.customerMaxQuantity > 0) {
        if (item.customerMaxQuantity < item.minBuyQuantity) {
          item.customerMaxQuantity = item.minBuyQuantity;
        }
      }
    },

    convertForm() {
      return new Promise((resolve, reject) => {
        if (this.form.items && this.form.items.length) {
          let ps = [];
          for (let item of this.form.items) {
            ps.push(this.$refs[`item_${item._id}`][0].validate());
          }
          Promise.all(ps)
            .then((_) => {
              let err = [];
              this.form.items.forEach((o) => {
                o.items.forEach((so, si) => {
                  if (!so.goodsList || !so.goodsList.length) {
                    err.push(`套餐【${o.name}】，内容${si + 1}没有选择商品`);
                  }
                });
              });
              if (err.length) {
                this.$alert(err.join("<br />"), "系统提示", {
                  dangerouslyUseHTMLString: true,
                  type: "warning",
                });
                reject();
              } else {
                let form = JSON.parse(JSON.stringify(this.form));
                form.target = form.target.join(",");
                resolve(form);
              }
            })
            .catch((_) => {
              this.currentTab = "detail";
              reject();
            });
        } else {
          this.$alert("请至少创建一个组合套餐。", "系统提示", {
            dangerouslyUseHTMLString: true,
            type: "warning",
          });
          this.currentTab = "detail";
          reject();
        }
      });
    },
    doSave(callback) {
      this.$refs["form"]
        .validate()
        .then((_) => {
          this.convertForm().then((form) => {
            if (form) {
              this.saving = true;
              (this.form.id ? combine.edit : combine.add)(form)
                .then((res) => {
                  this.$emit("update:isCopy", false);
                  if (typeof callback === "function") callback();
                  else {
                    this.$parent.init();
                    if (res && res.id) {
                      this.$emit("update:id", res.id);
                      this.$nextTick((_) => {
                        this.resetForm();
                      });
                    }
                    this.$notify({
                      title: "保存组合销售成功",
                      type: "success",
                      duration: 2500,
                    });
                  }
                })
                .finally((_) => {
                  this.saving = false;
                });
            }
          });
        })
        .catch((_) => {
          this.currentTab = "base";
        });
    },
    doStart() {
      let _exec = (_) => {
        this.operaing = true;
        combine
          .start(this.form.id)
          .then((res) => {
            this.form.status = "start";
            this.$notify({
              title: "启用成功",
              type: "success",
              duration: 2500,
            });
            this.$parent.init();
          })
          .finally((_) => {
            this.operaing = false;
          });
      };
      if (this.form.status === "init") {
        this.$confirm(
          "启用组合销售后，不能再对该活动内容进行编辑。<br />您确定要启用该组合销售吗？",
          "操作确认",
          { type: "warning", dangerouslyUseHTMLString: true }
        ).then((_) => {
          this.doSave(_exec);
        });
      } else if (this.form.status === "stop") {
        this.$confirm("您确定要启用该组合销售吗？", "操作确认", {
          type: "warning",
        }).then(_exec);
      }
    },
    doStop() {
      if (this.form.status === "start") {
        this.$confirm("您确定要停用该组合销售吗？", "操作确认", {
          type: "warning",
        }).then((_) => {
          this.operaing = true;
          combine
            .stop(this.form.id)
            .then((res) => {
              this.form.status = "stop";
              this.$notify({
                title: "停用成功",
                type: "success",
                duration: 2500,
              });
              this.$parent.init();
            })
            .finally((_) => {
              this.operaing = false;
            });
        });
      }
    },
    resetForm() {
      this.maxItemIndex = 0;
      if (this.id) {
        this.loading = true;
        combine
          .get(this.id)
          .then((res) => {
            res.items.forEach((o) => {
              if (this.isCopy) {
                o.id = null;
                o.promotionId = null;
                o.fileId = this.$uuid();
              }
              o._id = this.$uuid();
              o._maxItemIndex = 0;
              if (o.displayNo > this.maxItemIndex) {
                this.maxItemIndex = o.displayNo;
              }
              (o.items || []).forEach((so) => {
                if (this.isCopy) {
                  so.id = null;
                  so.groupSalesId = null;
                  so.promotionId = null;
                }
                so._id = this.$uuid();
                if (!so.displayNo) so.displayNo = o._maxItemIndex + 1;
                if (so.displayNo > o._maxItemIndex) {
                  o._maxItemIndex = so.displayNo;
                }
                so._maxItemIndex = 0;
                (so.goodsList || []).forEach((g) => {
                  if (this.isCopy) {
                    g.id = null;
                    g.promotionId = null;
                    g.groupSalesId = null;
                    g.groupSalesItemId = null;
                  }
                  g._id = this.$uuid();
                  if (!g.displayNo) g.displayNo = so._maxItemIndex + 1;
                  if (g.displayNo > so._maxItemIndex) {
                    so._maxItemIndex = g.displayNo;
                  }
                });
                so._unify = {
                  saveAmount: so.goodsList && so.goodsList.length ? so.goodsList[0].saveAmount : 0,
                  price: so.goodsList && so.goodsList.length ? so.goodsList[0].price : 0,
                };
              });
            });
            if (res.target) {
              if (res.targetType === "distributorArea") {
                res.target = (res.target || "").split(",");
              } else {
                res.target = (res.target || "").split(",").map(Number);
              }
            } else {
              res.target = [];
            }
            if (this.isCopy) {
              res.name += "【复制】";
              res.id = null;
              res.status = "init";
            }
            // console.log(res);
            this.form = res;
          })
          .finally((_) => {
            this.loading = false;
          });
      } else {
        this.form = {
          name: null,
          beginTime: null,
          endTime: null,
          method: "discount", //促销方式
          targetType: "all", //all  distributor distributorType
          target: [],
          maxTimes: 0,
          mutex: true,
          status: "init",
          items: [],
        };
        this.handleAddItem();
      }
    },
    // 自定义客户限量
    handleCustomerLimit(target, isNoView) {
      if (isNoView == true) {
        this.editCustomer.isNoView = true;
        this.editCustomer.target = target;
        this.editCustomer.show = true;
      } else {
        this.editCustomer.isNoView = false;
        this.editCustomer.target = target;
        this.editCustomer.show = true;
      }
    },
  },
  mounted() {
    this.resetForm();
  },
};
</script>

<style lang="less">
.marketing-combine-edit-form {
  border: #e4e7ed solid;
  border-width: 1px 0;

  &:last-of-type {
    margin-bottom: 22px;
  }
}

.marketing-combine-edit-item {
  border: #e4e7ed solid 1px;
  padding: 10px 20px;
  border-radius: 4px;
  margin-bottom: 22px;
}
</style>